.landing {
    &__video {

        &__wrapper {
            position: relative;
            overflow: hidden;


        }

        &__container {

            height: 48rem;
            width: calc(100vw - 300px);

            position: relative;

            @include respond(tab-land) {
                width: 100vw;
            }


            /*
            --mask:
                radial-gradient(223.61px at 50% calc(100% - 300px), #000 99%, #0000 101%) calc(50% - 200px) 0/400px 100%,
                radial-gradient(223.61px at 50% calc(100% + 200px), #0000 99%, #000 101%) 50% calc(100% - 100px)/400px 100% repeat-x;
            -webkit-mask: var(--mask);
            mask: var(--mask);
            */
            
            &::after {}

            &::before {}

        }

        &__vid {
            width: 100vw;
            height: auto;
            margin: auto;
            display: block;

            @include respond(phone-large) {
                height: 100%;
                object-fit: cover;
            }
        }

        &__text {
            background-image: linear-gradient(to right, rgba($color-black, 0.5), rgba($color-black, 0.6));
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            color: $color-white;

            @include respond(phone-large) {
                padding-top: 12rem;
                justify-content: flex-start;
            }

            @include respond(phone-medium) {
                padding-top: 10rem;
            }


            &__main {

                font-size: 5rem;
                animation: moveInRight 1s ease-out;

                @include respond(tab-land) {
                    font-size: 3.6rem;
                    text-align: center;
                }

                @include respond(phone-large) {
                    font-size: 2.5rem;
                }

                span {
                    color: $color-tertiary-dark;
                    font-weight: bold;

                }
            }

            &__sub {

                font-size: 3.5rem;
                margin: 2rem 0;
                animation: moveInLeft 1s ease-out;

                @include respond(tab-land) {
                    font-size: 2.8rem;
                    text-align: center;
                }

                @include respond(phone-large) {
                    font-size: 2rem;

                }

            }
        }

        &__btnsection {}

        &__btn {
            background-color: $color-primary;
            animation: moveInBottom 1s ease-out;
        }
    }
}