.mainservices {
    display: flex;
    flex-direction: column;
    gap: 4rem;

    &__item {

        padding: 2rem;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 2rem;

        h2 {
            text-align: center;
            font-size: 3rem;
            // margin: 2rem 0;
        }

        img {
            width: 50rem;
            height: auto;

            @include respond(phone-large) {
                width: 90vw;
            }
        }

        &__content {
            padding: 4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 2rem;

            &__intro {
                
                &::first-letter {

                    font-size: 2.5rem;
                    color: $color-primary;
                }
            }

            &__services {

                h4 {
                    margin: 2rem 0;
                }

                p {
                    margin-bottom: 2rem;
                    span {
                        font-weight: bold;
                    }
                }
            }

            &__factors {

                h4 {
                    margin: 2rem 0;
                }

                p {
                    margin-bottom: 2rem;

                    span {
                        font-weight: bold;
                    }
                }
            }

            &__challenges {

                h4 {
                    margin: 2rem 0;
                }

                p {
                    margin-bottom: 2rem;

                    span {
                        font-weight: bold;
                    }
                }
            }

            &__solutions {

                h4 {
                    margin: 2rem 0;
                }

                p {
                    margin-bottom: 2rem;

                    span {
                        font-weight: bold;
                    }
                }
            }

            &__conclusion {

                h4 {
                    margin: 2rem 0; 
                }
            }
        }
    }
}