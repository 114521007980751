.journey {

    margin: 4rem 0;
    margin-bottom: 8rem;

    h2 {

    }

    &__intro {
        padding: 2rem 5rem;
        text-align: center;
    }

    &__body {
        display: flex;
        gap: 2rem;
        justify-content: center;
        align-items: center;
        padding: 4rem 0;

        @include respond(tab-land) {
            flex-direction: column;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        &__icon {
            padding: 2rem;
            background-color: $color-primary;

            display: flex;
            justify-content: center;
            align-items: center;

            transform: translate(1rem, 3rem);

            svg {
                width: 4rem;
                height: 4rem;
                color: $color-white;
            }

        }

        &__content {
            font-size: 1.8rem;
            padding: 3rem 1rem;
            border: 2px solid $color-primary;
            
            span {
                font-size: 2.2rem;
                color: $color-primary;
                font-weight: bold;
            }
        }
    }
}