.footer {
    background-color: $color-grey-dark-3;
    padding: 10rem 0;
    font-size: 1.4rem;
    color: $color-grey-light-1;

    &__logo-box {
        text-align: center;
        margin-bottom: 8rem;

        @include respond(tab-land) {
            margin-bottom: 6rem;
        }
    }

    &__logo {
        width: 20rem;
        height: auto;
    }

    &__navigation {
        border-top: 1px solid $color-grey-dark;
        padding-top: 2rem;
        display: inline-block;

        @include respond(tab-land) {
            width: 100%;
            text-align: center;
        }
    }

    &__header {
        font-size: 2rem;
        text-align: center;
        font-weight: bold;
    }

    &__list {
        list-style: none;
    }

    &__item {
        display: inline-block;

        &:not(:last-child) {
            margin-right: 1.5rem;
        }
    }

    &__link {
        &:link,
        &:visited {
            color: $color-grey-light-1;
            background-color: $color-grey-dark-3;
            text-decoration: none;
            text-transform: uppercase;
            display: inline-block;
            transition: all .2s;
        }

        &:hover,
        &:active {
            color: $color-primary;
            box-shadow: 0 1rem 2rem rgba($color-black, .4);
            transform: rotate(5deg) scale(1.3);
        }
    }

    &__copyright {
        // border-top: 1px solid $color-grey-dark;
        padding-top: 2rem;
        width: 80%;
        float: right;

        @include respond(tab-land) {
            width: 100%;
            float: none;
        }
    }

    &__list {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
    }
}