.track {

    padding: 4rem;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include respond(phone-large) {
        padding: 2rem;
    }
    

    &__form {
        width: 70%;
        border: 1px solid $color-black;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 2rem;
        margin-bottom: 2rem;

        padding: 8rem 2rem;

        @include respond(phone-large) {
            width: 95%;

            justify-content: center;
            align-items: center;
        }


        @include respond(phone-small) {
            width: 98%;
        }

        &__header {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 2rem;

            h4 {
                text-align: center;
                font-size: 2rem;
            }

            img {
                width: 5rem;
                height: 5rem;
            }
        }

        &__inputbox {

            @include respond(phone-large) {
                width: 100%;
                padding: 1rem;
            }

            label {
                font-weight: bold;
            }
        }

        &__input {

            padding: 1rem 1.5rem;
            width: 40rem;
            font-size: 1.4rem;


            @include respond(phone-large) {
                width: 95%;
                padding: 8px;
            }

            &__wrap {
                display: flex;
                
                @include respond(phone-large) {
                    padding: 5px;
                }
            }

            &__icon {
                width: 2rem;
                height: 2rem;


                &__box {

                    padding: 1rem;
                    border: 2px solid $color-black;


                }
            }

            &__label {


                p {
                    span {}
                }
            }

        }

        &__btn {
            margin-top: 3rem;
            background-color: $color-primary;
            width: 30rem;
        }
    }





}