.shipping {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem;
    gap: 5rem;

    @include respond(phone-large) {
        padding: 2rem;
    }

    &__item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        padding: 3rem;



        &__header {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 3rem;

            p {
                width: 4rem;
                height: 4rem;
                background-color: $color-primary;
                border-radius: 50%;
                color: white;
                font-weight: bold;
                font-size: 2rem;

                display: flex;
                justify-content: center;
                align-items: center;
            }

            h4 {
                font-size: 2rem;
            }
        }

        &__content {

            display: flex;
            justify-content: center;
            align-items: center;
            gap: 3rem;

            @include respond(phone-large) {
                flex-direction: column-reverse;
            }

            p {
                flex: 3;

                &::first-letter {
                    font-size: 2rem;
                    color: $color-primary;
                }
            }

            &__img {

                flex: 2;

                display: flex;
                justify-content: center;
                align-items: center;

                img {

                    width: 20rem;
                    height: 20rem;
                }
            }
        }
    }
}

.shipping__item:nth-child(2n)>.shipping__item__content {

    flex-direction: row-reverse;

    @include respond(phone-large) {
        flex-direction: column-reverse;
    }

}