.mapcontent {

    margin-bottom: 8rem;

    &__map {

        padding: 3rem;
        // height: 60rem;
        // width: calc(100vw - 300px);
    }

    &__btnsection {

        display: flex;
        justify-content: center;
        align-items: center;

        margin: 3rem 0;
    }

    &__btn {
        background-color: $color-primary;
        width: 30rem;
    }
}