.process {
    margin-top: 2rem;
    margin-bottom: 8rem;

    h2 {}

    &__intro {
        text-align: center;
    }

    &__body {
        display: flex;
        justify-content: center;
        align-items: center;

        padding: 3rem 0;

        @include respond(tab-land) {
            flex-direction: column;
        }
    }

    &__item {

        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        
        &__number {
            width: 2.6rem;
            height: 2.6rem;
            background-color: $color-primary;
            border-radius: 50%;
            color: $color-white;
            font-weight: bold;
            display: flex;
            justify-content: center;
            align-items: center;

            position: absolute;
            top: 50%;
            left: -1.3rem;
            transform: translateY(-50%);

            @include respond(tab-land) {
                top: 0%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        &__img {

            border: 2px solid $color-primary;
            border-radius: 48%;
            padding: 2rem;

            img {
               width: 10rem;
               height: 10rem; 
            }

        }

        &__divider {

            height: 8px;
            width: 7rem;
            background-color: $color-primary;

            @include respond(tab-land) {
                width: 8px;
                height: 7rem;
            }
        }

        p {
            font-weight: bold;
        }
    }
}