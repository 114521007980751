// GRID
$grid-width: calc(100vw - 300px);
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;
$gutter-horizontal: 6rem;
$gutter-horizontal-small: 3rem;

.small-margin-right {
    margin-right: 4rem !important;
}

.composition {
    position: relative;

    &__photo {
        width: 55%;
        box-shadow: 0 1.5rem 4rem rgba($color-black, .4);
        border-radius: 2px;
        position: absolute;
        z-index: 10;
        transition: all .2s;
        outline-offset: 2rem;

        @include respond(tab-land) {
            float: left;
            position: relative;
            width: 33.33333333%;
            box-shadow: 0 1.5rem 3rem rgba($color-black, .2);
        }

        &--p1 {
            left: 0;
            top: -2rem;

            @include respond(tab-land) {
                top: 0;
                transform: scale(1.2);
            }
        }

        &--p2 {
            right: 0;
            top: 2rem;

            @include respond(tab-land) {
                top: -1rem;
                transform: scale(1.3);
                z-index: 100;
            }
        }

        &--p3 {
            left: 20%;
            top: 10rem;

            @include respond(tab-land) {
                top: 1rem;
                left: 0;
                transform: scale(1.1);
            }
        }

        &:hover {
            outline: 1rem solid $color-primary;
            transform: scale(1.05) translateY(-.5rem);
            box-shadow: 0 2.5rem 4rem rgba($color-black, .5);
            z-index: 20;
        }

    }

    &:hover &__photo:not(:hover) {
        transform: scale(.95);
    }

    //composition:hover composition__photo:not(:hover) {
}

.row {
    max-width: $grid-width;
    margin: 0 4rem;


    &:not(:last-child) {
        margin-bottom: $gutter-vertical;

        @include respond(tab-land) {
            margin-bottom: $gutter-vertical-small;
        }
    }

    @include respond(tab-land) {
        max-width: 100vw;
        margin: 0 3rem;
    }

    @include respond(phone-large) {
        margin: 0 1.5rem;
    }

    @include clearfix;

    [class^="col-"] {
        float: left;

        &:not(:last-child) {
            margin-right: $gutter-horizontal;

            @include respond(tab-land) {
                margin-right: 0;
                margin-bottom: $gutter-vertical-small;
            }
        }

        @include respond(tab-land) {
            width: 100% !important;
        }
    }

    .col-1-of-2 {
        width: calc((100% - #{$gutter-horizontal}) / 2);

    }

    .col-1-of-3 {
        width: calc((100% - 2 * #{$gutter-horizontal}) / 3);
    }

    .col-2-of-3 {
        width: calc(2 * ((100% - 2 * #{$gutter-horizontal-small}) / 3) + #{$gutter-horizontal-small});
    }

    .col-1-of-4 {
        width: calc((100% - 3 * #{$gutter-horizontal}) / 4);
    }

    .col-2-of-4 {
        width: calc(2 * ((100% - 3 * #{$gutter-horizontal}) / 4) + #{$gutter-horizontal});
    }

    .col-3-of-4 {
        width: calc(3 * ((100% - 3 * #{$gutter-horizontal}) / 4) + 2 * #{$gutter-horizontal});
    }

}

.section-about {
    background-color: $color-grey-light-1;
    padding-top: 7rem;
    padding-bottom: 2rem;
    

    @include respond(tab-land) {
        padding: 5rem 0;
    }
}

.heading-primary {
    color: $color-white;
    text-transform: uppercase;

    backface-visibility: hidden;
    margin-bottom: 6rem;

    &--main {
        display: block;
        font-size: 6rem;
        font-weight: 400;
        letter-spacing: 3.5rem;

        animation-name: moveInLeft;
        animation-duration: 1s;
        animation-timing-function: ease-out;

        @include respond(phone-large) {
            letter-spacing: 1rem;
            font-family: 5rem;
        }

        /*
        animation-delay: 3s;
        animation-iteration-count: 3;
        */
    }

    &--sub {
        display: block;
        font-size: 2rem;
        font-weight: 700;
        letter-spacing: 1.75rem;
        animation: moveInRight 1s ease-out;

        @include respond(phone-large) {
            letter-spacing: .5rem;
        }
    }
}

.heading-secondary {
    font-size: 3.5rem;
    text-transform: uppercase;
    font-weight: 700;
    display: inline-block;
    background-image: linear-gradient(to right, $color-primary-light, $color-primary-dark);
    -webkit-background-clip: text;
    color: transparent;
    letter-spacing: .2rem;
    transition: all .2s;

    @include respond(tab-land) {
        font-size: 3rem;
    }

    @include respond(phone-large) {
        font-size: 2.5rem;
    }

    &:hover {
        transform: skewY(2deg) skewX(15deg) scale(1.1);
        text-shadow: .5rem 1rem 2rem rgba($color-black, .2);
    }
}

.heading-tertiary {
    font-size: $default-font-size;
    font-weight: 700;
    text-transform: uppercase;
}

.paragraph {
    font-size: $default-font-size;

    &:not(:last-child) {
        margin-bottom: 3rem;
    }
}

.u-center-text {
    text-align: center !important;
}

.u-margin-bottom-small {
    margin-bottom: 1.5rem !important;
}

.u-margin-bottom-medium {
    margin-bottom: 4rem !important;

    @include respond(tab-land) {
        margin-bottom: 3rem !important;
    }
}

.u-margin-bottom-big {
    margin-bottom: 8rem !important;

    @include respond(tab-land) {
        margin-bottom: 5rem !important;
    }
}

.u-margin-top-big {
    margin-top: 8rem !important;
}

.u-margin-top-huge {
    margin-top: 10rem !important;
}