.btn {
    &,
    &:link,
    &:visited {
        text-transform: uppercase;
        text-decoration: none;
        padding: 1.2rem 2.5rem;
        display: inline-block;
        border-radius: 10px;
        transition: all .2s;
        font-size: 1.5rem;
        font-weight: 500;
        color: white;
        border: none;
        cursor: pointer;
        // background-color: $color-primary;
        text-transform: capitalize;
        font-family: 'Sora';


        @include respond(phone-large) {
            font-size: 1.3rem;
            font-weight: 600;
            //padding: 1rem 1.6rem;
        }
    }

    &:active,
    &:focus {
        outline: none;
    }

    &--basic {
        background-color: $color-primary; 
    }

    &--green {
       background-color: green; 
    }

    &--open {
        background-color: $color-primary-dark;
    }

}