.header {
    display: none;
    width: 0;
    height: 8rem;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    @include respond(tab-land) {
        display: flex;
        width: 100vw;
        
    }

    &__main {
        width: 100%;

        display: flex;
        
        justify-content: space-between;
        align-items: center;
        padding: 2rem;

        @include respond(phone-medium) {
            padding: 2rem 1rem;
        }

        &__img {

            img {
                width: 20rem;
                height: auto;

                @include respond(phone-medium) {
                    width: 14rem;
                }

                @include respond(phone-small) {
                    width: 10rem;
                }
            }

        }

        &__menu {

            svg {
                width: 3rem;
                height: auto;

                @include respond(phone-medium) {
                    
                }

                
            }
        }
    }

    &__dropdown {

        display: none;
        width: 0;

        justify-content: center;
        align-items: center;
        animation: drop .3s ease-in-out;

        position: absolute;
        top: 8rem;
        left: 0;
        z-index: 99909;
        background-color: $color-white;

        ul {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            list-style: none;

            li {
                width: 100%;
                display: flex;
                // justify-content: center;
                align-items: center;
                gap: 2rem;
                padding: 2rem;

                &:not(:last-child) {

                    border-bottom: 1px solid $color-black;
                }

                span {}

                svg {
                    width: 2rem;
                    height: 2rem;
                }
            }
        }
    }
}