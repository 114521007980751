.help {

    display: flex;
    flex-direction: column;

    h3 {
        font-size: 2.5rem;
        color: $color-white;

        background-color: $color-primary-dark;

        padding: 2rem;

        @include respond(phone-large) {
            font-size: 2rem;
        }


    }

    h4 {

        padding: 2rem;
        font-size: 2rem;

        @include respond(phone-large) {
            font-size: 1.7rem;
            padding: 1.5rem;
        }

    }

    h5 {
        font-size: 1.8rem;

        @include respond(phone-large) {
            font-size: 1.5rem;
        }
    }

    &__container {
        padding: 2rem;

        display: flex;
        flex-direction: column;


    }

    &__item {

        display: flex;
        gap: 2rem;

        justify-content: flex-start;
        align-items: center;

        margin-bottom: 1.5rem;

        @include respond(phone-large) {
            margin-bottom: 1.5rem;
            gap: 1.6rem;
        }

        img {
            width: 3rem;
            height: 3rem;
        }

        span {
            color: $color-secondary-light;

            @include respond(phone-large) {
                display: none;
            }
        }

        &__content {

            display: flex;
            flex-direction: column;

            gap: 1rem;

           
            
            p {
                font-size: 1.6rem;

                @include respond(phone-large) {
                    font-size: 1.5rem;
                }
            }
        }

    }

    &__btnsection {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
    }

    &__btn {
        width: 25rem;
        background-color: $color-primary;

        @include respond(phone-large) {
            width: 90%;
        }
    }
}