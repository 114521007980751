.sidebar {
    background-color: $color-black;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100vh;
    width: 300px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    @include respond(tab-land) {
        display: none;
        width: 0;
    }

    &__logo {
        margin: 3rem 0;

        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 15rem;
            height: auto;
        }
    }

    &__legal {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 2rem 0;
        color: white;
        text-align: center;
        padding: 0 2rem;
        font-size: 1.3rem;
        font-weight: bold;
    }
}

.side-nav {
    font-size: 1.4rem;
    list-style: none;
    margin-top: 1.5rem;

    @include respond(tab-land) {
        display: flex;
        margin: 0;
    }

    &__item {
        position: relative;
        cursor: pointer;

        &:not(:last-child) {
            margin-bottom: .5rem;

            @include respond(tab-land) {
                margin: 0;
            }
        }

        @include respond(tab-land) {
            flex: 1;
        }
    }

    &__item::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 3px;
        background-color: $color-primary;
        transform: scaleY(0);
        transition: transform .2s,
                    width .4s cubic-bezier(1,0,0,1) .2s,
                    background-color .1s;
    }

    &__item:hover::before,
    &__item--active::before {
        transform: scaleY(1);
        width: 100%;
    }

    &__item:active::before {
        background-color: $color-primary-light;
    }

    &__link {
        color: $color-grey-light-1;
        text-decoration: none;
        text-transform: uppercase;
        display: block;
        padding: 1.5rem 3rem;
        position: relative;
        z-index: 10;

        display: flex;
        align-items: center;

        @include respond(tab-land) {
            justify-content: center;
            padding: 2rem;
        }

        @include respond(phone-large) {
            flex-direction: column;
            padding: 1.5rem .5rem;
        }
    }

    &__icon {
        width: 1.75rem;
        height: 1.75rem;
        margin-right: 2rem;
        fill: currentColor;

        @include respond(phone-large) {
            margin-right: 0;
            margin-bottom: .7rem;
            width: 1.5rem;
            height: 1.5rem;
        }
    }
}