.package {
    margin-bottom: 8rem;
    padding: 4rem;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 3rem;

    @include respond(phone-large) {
        padding: 2rem;
    }

    &__header {
        font-size: 2rem;
        font-weight: bold;
        text-align: center;

        margin: 2rem 0;
    }

    &__content {



        ul {
            list-style: none;

            display: flex;
            flex-direction: column;
            border: 1px solid $color-black;
            // padding: 4rem;


            li {

                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 3rem;
                padding: 2rem 8rem;

                @include respond(phone-large) {
                    padding: 2rem;
                }

                &:not(:last-child) {
                    border-bottom: 1px solid $color-black;
                }

                svg {
                    width: 3rem;
                    height: 3rem;
                }
            }
        }

    }

    &__details {

        display: flex;
        flex-direction: column;

        gap: 1rem;
        
        h4 {}

        p {}
    }

    &__btn {
        width: 30rem;
        background-color: $color-primary;
    }
}