.services {
    padding: 4rem;
    margin-bottom: 8rem;

    h2 {
        // text-align: center;
    }

    &__intro {
        margin: 4rem 0;
        text-align: center;
    }

    &__body {
        display: flex;
        justify-content: center;
        // align-items: center;

        gap: 3rem;

        @include respond(tab-land) {
            flex-direction: column;

            justify-content: center;
            align-items: center;
            gap: 8rem;
        }
    }

    &__item {
        width: 26rem;
        height: auto;

        @include respond(tab-land) {
            width: 70%;
        }

        @include respond(phone-large) {
            width: 90%;
        }

        @include respond(phone-medium) {
            width: 95%;
        }

        &__img {

            img {
                width: 25rem;
                height: 15rem;

                @include respond(tab-land) {
                    width: 95%;
                    height: 25rem;
                }
            }
        }

        h4 {
            margin: 1rem 0;
            font-size: 1.6rem;

            &::after {
                content: "";
                display: block;
                width: 50px;
                
                height: 0;
                border-bottom: 2px solid $color-primary;
                
            }
        }

        &__text {
            font-size: 1.3rem;
        }

        &__bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 5px;

            &__read {
                display: flex;
                gap: 1rem;
                justify-content: center;
                align-items: center;


                p {
                    font-size: 1.3rem;
                    color: $color-primary;
                    text-transform: uppercase;
                }

                svg {
                    width: 1.7rem;
                    height: 1.7rem;
                    color: $color-primary;
                }
            }

            &__add {
                width: 6rem;
                height: 6rem;
                background-color: $color-primary;
                clip-path: polygon(100% 0%, 100% 100%, 0% 100%);

                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                padding: 5px;
                cursor: pointer;
                transition: all .2s;

                &:hover {
                    background-color: $color-secondary-light;

                    & > svg {
                        transform: translateY(2px);
                    }
                }

                svg {
                    transition: all .2s;
                    width: 2rem;
                    height: 2rem;
                    color: white;
                    
                }
            }
        }
    }
}