.partners {

    h2 {}

    &__body {

        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        img {

            width: 20rem;
            height: 20rem;
        }
    }

}
