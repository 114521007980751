.bg-video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: .15;
    overflow: hidden;

    &__content {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    &__primary {
        opacity: 1;
    }
}

.section-stories {
    position: relative;
    padding: 8rem 0;

    margin: 4rem 0;

    @include respond(tab-land) {
        padding: 8rem 0;
    }
}

.story {
    width: 85%;
    margin: 0 auto;
    box-shadow: 0 3rem 6rem rgba($color-black, .1);
    background-color: rgba($color-white, .6);
    border-radius: 3px;
    padding: 6rem;
    padding-left: 9rem;
    font-size: $default-font-size;
    transform: skewX(-12deg);

    @include respond(tab-land) {
        width: 100%;
        padding: 4rem;
        padding-left: 7rem;
    }

    @include respond(phone-large) {
        transform: skewX(0);
        padding: 4rem;
        padding-left: 5rem;
    }

    &__shape {
        width: 15rem;
        height: 15rem;
        float: left;
        shape-outside: circle(50% at 50% 50%);
        -webkit-clip-path: circle(50% at 50% 50%);
        clip-path: circle(50% at 50% 50%);
        transform: translateX(-3rem) skewX(12deg);
        position: relative;

        @include respond(phone-large) {
            transform: translateX(-3rem) skewX(0);

            width: 10rem;
            height: 10rem;
        }
    }

    &__img {
        height: 100%;
        transform: translateX(-4rem) scale(1.4);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        transition: all .5s;
    }

    &__text {
        transform: skewX(12deg);

        @include respond(phone-large) {
            transform: skewX(0);
        }
    }

    &__caption {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 20%);
        color: $color-white;
        text-transform: uppercase;
        font-size: 1.7rem;
        text-align: center;
        opacity: 0;
        transition: all .5s;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    &:hover &__caption {
        opacity: 1;
        transform: translate(-50%, -50%);
    }

    &:hover &__img {
        transform: translateX(-4rem) scale(1);
        -webkit-filter: blur(3px) brightness(80%);
        filter: blur(3px) brightness(80%);
    }
}